<template>
  <div class="">
    <p class="section-title text-primary-100 font-medium">預約結帳資訊</p>
    <BaseElForm label-position="left" label-width="150px">
      <!-- <BaseElFormItem label="付款狀態">
        <div class="inline-flex items-center gap-[8px]">
          <Tag style="display: inline-flex" :type="displayData.status.tagType" disable-transitions>
            {{ displayData.status.name }}
          </Tag>
          <BaseElButton
            v-if="showRefundBtn"
            type="text"
            class="refund-btn underline"
            @click="modal.refund = true"
          >
            前往退款
          </BaseElButton>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="結帳總額"> {{ displayData.totalItemsPrice }} </BaseElFormItem>
      <BaseElFormItem label="實付金額"> {{ displayData.checkoutPrice }} </BaseElFormItem>
      <BaseElFormItem label="付款方式"> {{ displayData.paidType }} </BaseElFormItem>
      <BaseElFormItem label="交易序號"> {{ displayData.orderCode }} </BaseElFormItem> -->

      <BaseElFormItem v-for="struct in dataStruct" :key="struct.key" :label="struct.label">
        <div v-if="struct.items" class="flex items-center" :style="`gap: ${struct.itemsGap}px; min-height: 40px`">
          <template v-for="(item, idx) in struct.items">
            <p v-if="item.el === 'p'" :key="`item-${idx}`">
              {{ item.label }}
            </p>
            <Tag
              v-if="item.el === 'tag'"
              :key="`item-${idx}`"
              v-bind="item.props"
            >
              {{ item.label }}
            </Tag>

            <div
              v-if="item.el === 'materialIcon'"
              :key="`item-${idx}`"
              class="cursor-pointer"
              v-on="item.on"
            >
              <MaterialIcon color="var(--primary-100)" size="24">
                {{ item.icon }}
              </MaterialIcon>
            </div>

            <BaseElButton
              v-if="item.el === 'el-button' && item.if"
              :key="`item-${idx}`"
              v-bind="item.props"
              v-on="item.on"
            >
              前往退款
            </BaseElButton>
          </template>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <MarkRefundDialog
      v-if="modal.refund"
      :orderData="orderData"
      :data="refundData"
      :maxPrice="checkoutPrice || null"
      chargeType="checkout"
      @marked="$emit('refresh')"
      @close="modal.refund = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { get, filter, includes } from 'lodash'
import Tag from '@/components/Tag/Tag.vue'
import { paymentStatusConfig, paymentOptionsConfig } from '@/config/payment'
import MarkRefundDialog from './MarkRefundDialog.vue'
import MaterialIcon from '@/components/MaterialIcon.vue'
import copy from 'clipboard-copy'
import notifyMessage from '@/config/notifyMessage'
import { useExternalWallet } from '@/use/useExternalWallet'

export default defineComponent({
  name: 'CheckoutBlock',
  components: {
    Tag,
    MarkRefundDialog,
    MaterialIcon,
  },
  props: {
    reservation: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const modal = reactive({
      refund: false,
    })

    const isBatchOrder = computed(() => {
      return get(props.reservation, 'AppointmentOrder.AppointmentBatchOrder')
    })

    const orderData = computed(() => {
      return get(props.reservation, 'AppointmentOrder') || {}
    })

    const checkoutPrice = computed(() => {
      return get(props.reservation, 'AppointmentOrder.AppointmentCheckoutOrder.totalPrice')
    })

    const displayData = computed(() => {
      const status = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.status',
      )
      const checkoutPrice = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.totalPrice',
      )
      const totalItemsPrice = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.totalItemsPrice',
      )

      let displayPaidType
      displayPaidType =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        ) ||
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        )

      if (displayPaidType === 'offline') {
        displayPaidType =
          get(
            props.reservation,
            'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
          ) ||
          get(
            props.reservation,
            'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
          )
      }
      const paidType = displayPaidType === 'externalWallet' ? externalWalletName.value : get(paymentOptionsConfig, `${displayPaidType}.name`) || '-'
      return {
        status: get(paymentStatusConfig, status) || { name: '-', tagType: 'info' },
        checkoutPrice: checkoutPrice ? `$ ${checkoutPrice}` : '-',
        totalItemsPrice: totalItemsPrice ? `$ ${totalItemsPrice}` : '-',
        paidType: displayPaidType === 'free' ? '免付款' : paidType,
        orderCode: get(props.reservation, 'AppointmentOrder.code') || '-',
      }
    })

    const refundData = computed(() => {
      const paidType =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        ) ||
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidType',
        )
      const paidTypeComment =
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
        ) ||
        get(
          props.reservation,
          'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.paidTypeComment',
        )
      return {
        refundType: paidType,
        refundTypeComment: paidTypeComment,
      }
    })

    const showRefundBtn = computed(() => {
      const status = get(
        props.reservation,
        'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutPayment.status',
      )
      return status === 'waitRefund'
    })

    const dataStruct = computed(() => {
      // 所有欄位與資料
      const data = [
        {
          label: '付款狀態',
          key: 'status',
          itemsGap: 8,
          items: [
            {
              el: 'tag',
              props: {
                type: displayData.value.status.tagType,
                disableTransitions: true,
              },
              label: displayData.value.status.name,
            },
            {
              el: 'el-button',
              if: showRefundBtn.value,
              props: { type: 'text', class: 'refund-btn underline' },
              on: {
                click: () => {
                  modal.refund = true
                },
              },
              label: '前往退款',
            },
          ],
        },
        {
          label: '結帳總額',
          key: 'totalItemsPrice',
          items: [
            { el: 'p', label: displayData.value.totalItemsPrice },
          ],
        },
        {
          label: '實付金額',
          key: 'checkoutPrice',
          items: [
            { el: 'p', label: displayData.value.checkoutPrice },
          ],
        },
        {
          label: '付款方式',
          key: 'paidType',
          items: [
            { el: 'p', label: displayData.value.paidType },
          ],
        },
        {
          label: '交易序號',
          key: 'orderCode',
          itemsGap: 8,
          items: [
            {
              el: 'p',
              label: displayData.value.orderCode,
            },
            {
              el: 'materialIcon',
              icon: 'content_copy_filled',
              on: {
                click: () => {
                  try {
                    copy(displayData.value.orderCode)
                    window.$message.success(notifyMessage.copySuccess)
                  } catch (error) {
                    window.$message.error(error)
                  }
                },
              },
            },
          ],
        },
      ]

      // 依據條件顯示不同的欄位
      if (isBatchOrder.value) {
        return filter(data, (item) => includes(['totalItemsPrice'], item.key))
      }
      return data
    })
    onMounted(() => {
      getExternalWalletConfig()
    })
    return { displayData, modal, showRefundBtn, refundData, orderData, checkoutPrice, dataStruct }
  },
})
</script>

<style lang="postcss" scoped></style>
