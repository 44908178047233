import { render, staticRenderFns } from "./CheckoutBlock.vue?vue&type=template&id=496fb406&scoped=true"
import script from "./CheckoutBlock.vue?vue&type=script&lang=js"
export * from "./CheckoutBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "496fb406",
  null
  
)

export default component.exports